import React, { useEffect } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { LargeHeader } from "../TextComponents/TextComponents";
import Hero from '../Hero/Hero';
import HeroImage from './assets/Book Now_main image@2x.jpg';
import HeroImageBlur from './assets/Book Now_main image@2x_blur.jpg';
import HeroMobileImage from './assets/Mobile-Book Now_main image@2x.jpg';
import HeroMobileImageBlur from './assets/Mobile-Book Now_main image_blur@2x.jpg';


const Wrapper = styled.div` 
  position: relative;
`
const Content = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 216px;
  color: white;
`

const Maintenance = () => {

  return (
    <Wrapper>
      <Hero desktop={{src: HeroImage, placeholder: HeroImageBlur}} 
      mobile={{src: HeroMobileImage, placeholder: HeroMobileImageBlur}}/>
        <Content>
          <LargeHeader style={{margin: '0 0 25px'}}>Coming Soon</LargeHeader>
        </Content>
    </Wrapper>  
  )
  
}

export default Maintenance;