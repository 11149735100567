import ScrollMagic from "scrollmagic";

class ScrollDetection {
  controller: any;
  scene: any;

  constructor(trigger:HTMLDivElement, enter:any, leave:any) {
    this.controller = new ScrollMagic.Controller({loglevel: 0});
    this.scene = new ScrollMagic.Scene({triggerElement: trigger, triggerHook: 0, duration: trigger.offsetHeight})
      .addTo(this.controller)
      .on('enter', enter)
      .on('leave', leave);
  }

  destroy = () => {
    this.controller.destroy(true);
    this.controller = null;
    this.scene.destroy(true);
    this.scene = null;
  }

}

export default ScrollDetection;