import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import LinkFade from '../LinkFade/LinkFade';
import { TogenText } from '../Branding/Branding';
import CloseSvg from './assets/close.svg';
import HamburgerIcon from './assets/hamburger.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.nav`
position: fixed;
display: flex;
box-sizing: border-box;
padding: 35px 35px 0;
justify-content: space-between;
width: 100%;
left: 0;
z-index: 500;

&:before{
  height: 100px;
  width: 100%;
  background-image: linear-gradient(180deg,rgba(0,0,0,.2),hsla(0,0%,100%,0));
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  content: '';
}
`

const NavLink = styled(LinkFade)`
  text-decoration: none;
  color: inherit;
  z-index: 1;
`

const HamburgerWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  z-index: 1;
`

const MobileNavigation = (props:any) => {
  const { color, navitems } = props;
  const navRedux = useSelector(state => state.navReducer);
  const navColor = color;
  const [ isMenu, setIsMenu ] = useState(false);

  const onClose = () => {
    setIsMenu(false);
  }

  const onOpen = () => {
    setIsMenu(true);
  }

  return (
    <>
    <MobileMenu close={onClose} navitems={navitems} inProp={isMenu}/>
    <Wrapper>
      <NavLink to={`/`}><TogenText fill={navColor} width={70}/></NavLink>
      <HamburgerWrapper onClick={onOpen}><HamburgerIcon style={{transition:'stroke 200ms ease', stroke:navColor}}/></HamburgerWrapper>
    </Wrapper>
    </>
  )
}

export default MobileNavigation;


const MobileMenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 501;
  
`

const MobileMenuInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: #393939;
  padding: 115px 20px 61px 31px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const NavList = styled.ul<{color?:string}>`
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
  z-index: 1;
`

const NavListItem = styled.li<{active?:boolean}>`
  ${fontstack.default}
  ${type('body')}
  letter-spacing: 1px;
  opacity: ${props => props.active ? 1 : 0.5};
  user-select: none;

  &:not(:last-child) {
    margin: 0 0 30px;
  }
`

const CloseWrapper = styled.div`
  box-sizing: border-box;
  padding: 35px 35px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`

const CloseInner = styled.div`
  position: relative;
  width: 20px;
  cursor: pointer;
`

const MobileMenu = (props:any) => {

  const { close, navitems, inProp} = props;
  const navRedux = useSelector(state => state.navReducer);

  const defaultWrapperStyle = {
    transition: `background 200ms ease`,
    background:'rgba(255,255,255,0.4)'
  };

  const transitionWrapperStyles = {
    entering: { background:'rgba(255,255,255,0)' }, 
    entered: { background:'rgba(255,255,255,0.4)' },
    exiting: { background:'rgba(255,255,255,0)' },
    exited: { background:'rgba(255,255,255,0)' }
  };

  const defaultNavStyle = {
    transition: `opacity 200ms ease`,
    opacity: 1
  };

  const transitionNavStyles = {
    entering: { opacity: 0 }, 
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };
  
  return (
    <Transition in={inProp} timeout={200} mountOnEnter unmountOnExit>
      { state => (
        <MobileMenuWrapper style={{
          ... defaultWrapperStyle,
          ... transitionWrapperStyles[state]
        }} >
          <CloseWrapper style={{
          ... defaultNavStyle,
          ... transitionNavStyles[state]
        }}>
            <CloseInner onClick={close}>
              <CloseSvg />
            </CloseInner>
          </CloseWrapper>
          <MobileMenuInner style={{
          ... defaultNavStyle,
          ... transitionNavStyles[state]
        }} >
            <NavList>
              <NavListItem active={'/'===navRedux.currentPage ? true : false}><NavLink to={'/'}>Home</NavLink></NavListItem>
              { navitems ?
              navitems.map((item) => {

                return (
                  <NavListItem active={item.slug===navRedux.currentPage ? true : false}><NavLink to={item.slug}>{item.label}</NavLink></NavListItem>
                )
              }) : null }
            </NavList>
          </MobileMenuInner>
        </MobileMenuWrapper>
      )}
      
    </Transition> 
  )
}

export { MobileMenu }