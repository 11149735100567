import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import LinkFade from '../LinkFade/LinkFade';
import { SumiText, TogenText } from '../Branding/Branding';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.nav<{show?:boolean}>`
  position: fixed;
  display: flex;
  box-sizing: border-box;
  padding: 50px 80px 0;
  justify-content: space-between;
  width: 100%;
  left: 0;
  z-index: 999;
  transition: transform .4s ease;
  transform: translate(0,${props => props.show ? '0' : '-100%'});

  &:before{
    height: 100px;
    width: 100%;
    background-image: linear-gradient(180deg,rgba(0,0,0,.2),hsla(0,0%,100%,0));
    position: absolute;
    left: 0;
    top: 0;
    content: '';
  }
`

const NavLink = styled(LinkFade)<{hide?:boolean}>`
  text-decoration: none;
  color: inherit;
  z-index: 1;
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
`

const NavList = styled.ul<{color?:string}>`
  transition: 'color 200ms ease';
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: ${props => props.color ? props.color : 'black'};
  z-index: 1;
`

const NavListItem = styled.li`
  ${fontstack.default}
  ${type('caption')}
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:not(:last-child) {
    margin: 0 30px 0 0;
  }
`

const Navigation = (props:any) => {
  const { color, navitems, omitLogo } = props;
  const navRedux = useSelector(state => state.navReducer);
  const navColor = color;
  
  return (
    <Wrapper show={navRedux.showNav}>
      <NavLink to={`/`} hide={omitLogo}><TogenText fill={navColor} width={100}/></NavLink>
      <NavList color={navColor}>
        { navitems.map((item) => {
          return (
            <NavListItem><NavLink to={item.slug}>{item.label}</NavLink></NavListItem>
          )
        })}
      </NavList>
    </Wrapper>
  )
}

export default Navigation;