import ScrollMagic from "scrollmagic";

class AnimationDetection {
  controller: any;
  scenes: any = [];

  constructor(triggers:any, firedCallback: Function) {
    this.controller = new ScrollMagic.Controller({loglevel: 0});
    triggers.forEach((trigger,index) => {
      if(trigger) {
        this.scenes.push(new ScrollMagic.Scene({triggerElement: trigger, triggerHook: 1, duration: trigger.offsetHeight})
        .addTo(this.controller)
        .on('enter', () => firedCallback(index)));
      }
    })
  }

  destroy = () => {
    this.controller.destroy(true);
    this.controller = null;

    this.scenes.forEach((scene)=>{
      scene.destroy(true);
      scene = null;
    });
    this.scenes = null;
  }

}

export default AnimationDetection;