import React from 'react';
import styled from 'styled-components';
import Section from '../Section/Section';
import { TogenFooter } from '../Branding/Branding';
import useWindowSize from '../../utils/useWindowSize';
import { media } from '../../utils/mediaQuery';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';


const FooterWrapper = styled(Section)`
  margin-top: 200px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.medium`
    margin-top: 435px;
    margin-bottom: 265px;
  `}
`

const FooterCopy = styled.div`
  ${fontstack.default}
  ${type('overline')}
  color: #717171;
  margin: 40px 0 20px;
  text-align: center;

  ${media.medium`
    ${type('caption')}
    margin: 50px 0 20px;
  `}
`

const FooterCopyright = styled.div`
  ${fontstack.default}
  ${type('overline')}
  color: #717171;
  text-align: center;
`

const MailLink = styled.a`
  color: inherit;
  font-weight: 500;
  text-decoration: none;
`

const Footer = () => {
  const [ width, height ] = useWindowSize();
  const year = new Date().getFullYear();
  return (
    <FooterWrapper full none>
      <TogenFooter fill="#717171" width={width > 900 ? 175 : 115 }/>
      <FooterCopy>For any enquiries or to register your interest, please contact <MailLink href="mailto:info@pow-management.com">info@pow-management.com</MailLink></FooterCopy>
      <FooterCopyright>Copyright {year} Togen Niseko</FooterCopyright>
    </FooterWrapper>
  )
}

export default Footer;