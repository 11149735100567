export const setNavColor = (value) => ({
  type: 'SET_NAV_COLOR',
  payload: value
});

export const setNavDefaultColor = (value) => ({
  type: 'SET_NAV_DEFAULT_COLOR',
  payload: value
});


export const setNavTrigger = (value) => ({
  type: 'SET_NAV_TRIGGER',
  payload: value
});

export const setShowNav = (value) => ({
  type: 'SET_SHOW_NAV',
  payload: value
});

export const setCurrentPage = (value) => ({
  type: 'SET_CURRENT_PAGE',
  payload: value
});
