import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ScrollMagic from "scrollmagic";
import { createGlobalStyle } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollDetection from './ScrollDetection';
import AnimationDetection from './AnimationDetection';
import { setNavColor, setShowNav, setNavTrigger } from '../../actions';
import Navigation from '../Navigation/Navigation';
import Maintenance from '../Maintenance/Maintenance';
import MobileNavigation from '../Navigation/MobileNavigation';
import Footer from '../Footer/Footer';
import useWindowSize from "../..//utils/useWindowSize";
import './fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f2f2f2;
  }
  @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
`
const Layout = (props) => {
  const { children, trigger, footer, defaultColor, omitLogo } = props;
  const navItems = useStaticQuery(query).allPrismicNavigation.edges[0].node.data.nav_items;
  const dispatch = useDispatch();
  const controller = useRef();
  const scene = useRef();
  const updateController = useRef();
  const updateScene = useRef();
  const scrollInstance = useRef();
  const animationInstance = useRef();
  const animatedRefs = useRef([]);
  const [ Show, setShow ] = useState([]);
  const [ BlackNav, setBlacktNav ] = useState(false);
  const showRef = useRef();
  const cachedDirection = useRef();
  const counter = useRef(0);
  showRef.current = Show;

  const [ width, height ] = useWindowSize();

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => {
      //event.preventDefault()
    });
    
    updateController.current = new ScrollMagic.Controller();
    updateScene.current = new ScrollMagic.Scene()
      .addTo(updateController.current)
      .on("update", function (e) {
        const direction = e.target.controller().info("scrollDirection");

        if(direction !== cachedDirection.current) {
          if(direction === 'FORWARD') {
            dispatch(setShowNav(false));
          } else {
            dispatch(setShowNav(true));
          }
          cachedDirection.current = direction;
        }
      })
    if(animatedRefs.current && animatedRefs.current.length > 0){
      if(animationInstance.current) {
        animationInstance.current.destroy();
      }
      console.log(animatedRefs.current);
      animationInstance.current = new AnimationDetection(animatedRefs.current, animatedFired);
    }
    if(trigger) {
      scrollInstance.current = new ScrollDetection(trigger,onEnter,onLeave);
    }

    return () => {
      destroyScrollInstance();

      if(animationInstance.current) {
        animationInstance.current.destroy();
        animationInstance.current = null;
      }
    }
  }, [trigger, children])

  const destroyScrollInstance = () => {
    if(scrollInstance.current) {
      console.log('instance destroyed');
      scrollInstance.current.destroy();
      scrollInstance.current = null;
    }

    if(updateController.current && updateScene.current) {
      updateController.current.destroy(true);
      updateController.current = null;
      updateScene.current.destroy(true);
      updateScene.current = null;
    }
  }

  const onEnter = (e) => {
    setBlacktNav(false);
  }

  const onLeave = (e) => {
    setBlacktNav(true);
  }

  const animatedFired = (index) => {
      const newArray = [...showRef.current]; 
      newArray[index] = true; 
      setShow(newArray);
  }

  const processChildren = (children) => {
    animatedRefs.current = [];
    counter.current = 0;
    return React.Children.map(children, (child) => {
      

      if(child && child.type === React.Fragment) {
        let processed = [];
        let allChildren = child.props.children;
        
        if(allChildren && allChildren.length > 1) {
          allChildren.forEach((fragChild) => {
            processed.push(processChild(fragChild))
          }) 
        } else {
          processed.push(processChild(allChildren))
        }
      

        return processed;
      }

      return processChild(child)
      
    });
  }

  const processChild = (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child) && child.props.animated) {
     
      let index = counter.current;
      counter.current++;
      const comp = React.cloneElement(child, { show: Show[index] ? true : false });
      return <div ref={el => animatedRefs.current[index] = el}>{comp}</div>;
      
    }

    return child;
  }

  const childrenEl = processChildren(children);

  return (
    <>
      <GlobalStyle />
      { process.env.GATSBY_IN_SITE_MAINTENANCE === "true" ? <Maintenance /> : <>
        { width > 900 ? <Navigation omitLogo={omitLogo} navitems={navItems} color={ BlackNav ? 'black' : defaultColor } /> : <MobileNavigation navitems={navItems} color={ BlackNav ? 'black' : defaultColor }/>}
        { childrenEl }
        { footer ? <Footer /> : null }
      </>
      }
      
      
    </>
  )
}

export default Layout;

const query = graphql`
  query Nav {
    allPrismicNavigation {
      edges {
        node {
          data {
            nav_items {
              slug
              label
            }
          }
        }
      }
    }
  }
`

